/* Theme colors and dark mode overrides */
$primary: #3c2a5e;
$ripple: #65479f;
$white: #ffffff;
$dark-background: #282828;
/* Dark mode background for buttons */
$dark-border: #444444;
/* Dark mode border color */
$dark-text: #E0E0E0;
/* Light text for better visibility in dark mode */

.button {
  min-width: 150px;
  border-radius: 4px;
  padding: 0.625rem 0.875rem;
  font-size: 1rem;
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: none;
  transition: 0.5s ease;
  cursor: pointer;

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .anticon,
    i {
      margin-right: 10px;
    }
  }

  &:focus,
  &:hover {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.primary-btn {
  background-color: $primary;
  color: $white;
  border: none;

  &:hover {
    background-color: $ripple;
  }

  .dark-mode & {
    background-color: $ripple;
    /* Darker primary color for contrast */
    color: $dark-text;

    &:hover {
      background-color: darken($ripple, 10%);
      /* Slightly darker on hover */
    }
  }
}

.secondary-btn {
  background-color: $white;
  color: #4b5658;
  border: 2px solid #d4d9dd;
  padding: 0.5rem 0.875rem;
  transition: 0.5s ease;

  &:hover {
    background: #d4d9dd;
  }

  .dark-mode & {
    background-color: $dark-background;
    color: $dark-text;
    border-color: $dark-border;

    &:hover {
      background-color: lighten($dark-background, 10%);
    }
  }
}

.btn-border {
  background-color: $white;
  color: $primary;
  border: 1px solid $primary;

  &:hover {
    background-color: inherit;
    border-color: $ripple;
  }

  .dark-mode & {
    background-color: $dark-background;
    color: $ripple;
    border-color: $ripple;

    &:hover {
      border-color: lighten($ripple, 10%);
    }
  }
}

.btn-small {
  min-width: 0;
  padding: 0.25rem 0.875rem;
}

.btn-text {
  background-color: $white;
  color: $primary;
  border: none;

  &:hover {
    background-color: inherit;
  }

  .dark-mode & {
    background-color: $dark-background;
    color: $ripple;

    &:hover {
      color: lighten($ripple, 10%);
    }
  }
}

.danger-btn {
  background-color: #ff4d4f;  
  color: #fff;  
  border: none;  

  &:hover {
    background-color: #ff7875;  
  }

  &:disabled {
    background-color: #ffccc7; 
    cursor: not-allowed;
  }
} 
 
.step-progress {
  display: flex;
  align-items: center;
  width: 93%;
}

.step {
  position: relative;
  background-color: #3c2a5e;  
  color: white;
  padding: 1rem 2rem;
  clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%);
  margin-right: -20px; /* Reduces space between arrows */
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  z-index: 1;
  flex-grow: 1; /* Allows the arrow to stretch and fill available space */
  text-align: center; /* Centers the text inside */
}

.hover-enabled:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #65479f;  
  z-index: -1;
  clip-path: inherit;  
  transition: background-color 0.3s ease;
}

.step.completed {
  background-color: #472879 !important;  
}

.step.locked {
  background-color: #472879 !important;  
  cursor: not-allowed;
}

.step.current-step {
  background-color: #3c2a5e;  
  color: white;  
}

.step-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
}
.step-arrow .step-icon {
  margin-right: 10px; /* Adds space between the icon and text */
}
.clicked-step {
  background-color: rgb(175, 173, 173) !important;  
  color: #3c2a5e !important;  
}

// .step-progress {
//   display: flex;
//   align-items: center;
// }

// .step {
//   display: flex;
//   align-items: center;
//   margin-right: 20px;
// }

// .step-box {
//   font-weight: bold;
//   padding: 10px 20px;
//   background-color: #3c2a5e;  
//   color: white;
//   border-radius: 8px;  
//   text-align: center;
//   white-space: nowrap;
//   transition: background-color 0.3s ease, opacity 0.3s ease;
// }

// .step.completed .step-box {
//   background-color: #472879 !important;  
// }

// .step.locked .step-box {
//   background-color: #472879 !important;  
//   cursor: not-allowed;
// }

// .step.current-step .step-box {
//   background-color: #3c2a5e;  
//   color: white;  
// }

// .hover-enabled:hover .step-box {
//   background-color: #65479f;  
//   cursor: pointer;
// }

// .clicked-step .step-box {
//   background-color:  whitesmoke !important;  
//   color: #3c2a5e !important;  
//   border: 2px solid #3c2a5e !important;
// }

// .step-line {
//   width: 115px; 
//   height: 2px;
//   background-color: #3c2a5e;  
//   border-bottom: 2px  ;
//   margin-left: 10px;
//   margin-right: 10px;
//   transition: background-color 0.3s ease;
// }

// .step.completed .step-line {
//   background-color: #472879 !important;  
// }

// .step.locked .step-line {
//   background-color: #472879 !important;  
// }

.evaluation {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;


  .dark-mode & {
    background-color: #272525;
    color: white;
  }
  .header {
    background: #3c2a5e;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .headingBar {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    color: aliceblue;
  }

  .section {
    background: #ffffff;
    display: flex;
    height: calc(100vh - 70px);
    flex-direction: column;
    padding: 0;
    position: relative;

    .sectionHead {
      align-items: center;
      height: 65px;
      gap: 10px;
      display: flex;
      border-bottom: 1px solid #ccc; /* Corrected border-bottom property */
      width: 100%;
      padding: 10px;
      overflow-x: auto; /* Corrected overflow-x property */
      overflow-y: hidden;
      white-space: nowrap; /* Corrected white-space property */

      /* Scrollbar styling */
      &::-webkit-scrollbar {
        width: 8px; /* width of vertical scrollbar */
        height: 8px; /* height of horizontal scrollbar */
      }

      /* For Firefox */
      scrollbar-width: thin; /* "auto" or "thin" for Firefox */
      scrollbar-color: #c3bad2 transparent; /* scrollbar color and track color */
    }

    .sectionBody {
      height: calc(100vh - 190px);
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
    }
    .sectionBody2 {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      width: 100%;
      &::-webkit-scrollbar {
        width: 0.3rem;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
    }
    .sectionFooter {
      align-items: center;
      height: 60px;
      display: flex;
      border-top: 1pt solid #ccc;
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 10px;
      z-index: 5;
      justify-content: space-between;
      .buttonSpace {
        gap: 10px;
        display: flex;
      }
    }
  }
  .tabHeader {
    width: 100%;
    align-items: flex-end;
    background: #ffffff;
    border-left: 1pt solid #ccc;
    height: calc(100vh - 70px);
    padding: 0;
    .ant-tabs-tab {
      flex: 1;
      text-align: center;
    }
    .tabName {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
    }
    .sidebar {
      overflow: auto;
      max-height: calc(100vh - 150px);
    }
  }
}

.descriptionBox {
  background-color: #ffffff;
  .ant-descriptions-item-label {
    color: #fafafa;
    background: #3c2a5e;
  }
}

.vendorSignature {
  .ant-upload.ant-upload-select-picture-card {
    width: 200px; // Adjust width of the picture card
    height: 80px;
  }
  .ant-upload-list-picture-card {
    .ant-upload-list-item {
      width: 200px; // Adjust width of the picture card
      max-height: 80px; // Adjust height to maintain aspect ratio 4:3
    }
    .ant-upload-list-item-info {
      padding-top: 0; // Remove extra padding
    }
  }
  .ant-upload-list-picture .ant-upload-list-item-image,
  .ant-upload-list-picture-card .ant-upload-list-item-image {
    object-fit: cover;
  }
}

.multipleChoice {
  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
  }

  .ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
  }
}

.optionsCard {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  .optionAvatar {
    width: 40px;

    .optionAvatarInside {
      background-color: #ffffff;
      color: #3c2a5e;
      border: 1pt solid #3c2a5e;
    }
  }
}
.optionsCardSuccess {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  color: green;
  text-shadow: 0 0 1px green;
  transition: text-shadow 0.3s ease-in-out;
  border: 1.2pt solid green;
  cursor: pointer;

  border-radius: 10px;
  .optionAvatar {
    width: 40px;
    .optionAvatarInside {
      background-color: #ffffff;
      border: 1pt solid green;
      color: green;
      text-shadow: 0 0 1px green;
      transition: text-shadow 0.3s ease-in-out;
      box-shadow: 0 0 2px green;
      transition: box-shadow 0.3s ease-in-out;
    }
  }
}

.QuestionsList {
  background-color: #ffffff;
}

.QuestionPannedButtons {
  .activeBtn {
    background-color: #ffffff;
    border: 1pt solid #3c2a5e;
    color: #3c2a5e;
    font-size: larger;
  }
  .NonActiveBtn {
    background-color: #3c2a5e;
  }
}

.mainAP {
  background-color: #bab7b7;
  top: 0;
  padding: 15px;
  position: sticky;
  .mainBody {
    border-radius: 10px;
    font-family: sans-serif;
  }
}

.AuditSectionTable {
  font-weight: 600;
  font-size: medium;
}

$primary: #3c2a5e;
$secondary: #65479f;
$white: #ffffff;
$border-color: #f0f0f0;
$table-hover: #f1edf7;

.table-card-audit {
  border: 1pt solid $border-color;
  .ant-table-tbody {
    .dark-mode & {
      background-color: #272727;
      color: #f0f0f0;
    }
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active,
  .ant-table-filter-trigger.active {
    color: #ffffff;
  }
  .ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
    padding: 12px 10px;
  }
  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: $primary;
  }
  .ant-table-column-sorters {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ant-table-thead
    th.ant-table-column-has-sorters:hover
    .ant-table-filter-trigger-container {
    background: $primary;
  }
  .ant-table-filter-trigger-container-open,
  .ant-table-filter-trigger-container:hover,
  .ant-table-thead
    th.ant-table-column-has-sorters:hover
    .ant-table-filter-trigger-container:hover {
    background: $primary;
  }
  .ant-table-filter-trigger-container-open .ant-table-filter-trigger,
  .ant-table-filter-trigger:hover {
    background: $primary;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #635185 !important;
    border-color: #3c2a5e;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #3c2a5e;
    border-color: #3c2a5e;
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #3c2a5e;
  }

  .ant-table-thead > tr > th {
    color: $white;
    background: $primary;
    border-bottom: 1px solid $border-color;
    font-weight: 400;
    font-weight: 600;
  }

  .ant-pagination {
    .dark-mode & {
      color: $white;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      line-height: 16px;
      .dark-mode & {
        color: $white;
      }
    }

    .ant-pagination-prev:hover .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link {
      color: $primary;
      .dark-mode & {
        color: $white;
      }
    }

    .ant-pagination-item:hover a {
      color: $primary;
      .dark-mode & {
        color: $white;
        background: #4b4b4b;
      }
    }

    .ant-pagination-item-active {
      background: $primary;
      color: $white;
      border: none;
      .dark-mode & {
        background: #4b4b4b;
        color: $white;
      }
    }

    .ant-pagination-item-active a {
      color: $white;
    }

    .ant-pagination-item-active:hover a {
      color: $white;
    }
  }
}

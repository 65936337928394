.app-layout {
  width: 100%;
  height: 100%;
  background: #fff;
}
.ant-layout {
  background-color: #ececec;
  .dark-mode & {
    background: #1a1818;
  }
}
.site-layout {
  margin-left: 0px;

  .site-layout-background {
    background: #fff;
    .dark-mode & {
      background: #272525;
    }
  }
}

@media only screen and (min-width: 960px) {
  .site-layout {
    margin-left: 250px;
  }
}

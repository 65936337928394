@import '~antd/dist/antd.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import './icon.scss';

$font-dark: #090909;
$white: #f2f2f2;
$success: #277233;
$danger: #ff6666;

body {
  background-color: #ececec;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: $font-dark;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  .dark-mode & {
    background-color: #1a1818;
  }
}

// body::-webkit-scrollbar {
// 	width: 0.625rem;
// }

// body::-webkit-scrollbar-track {
// 	background: #ffffff;
// }

// body::-webkit-scrollbar-thumb {
// 	background: #bdc3c7;
// 	border-radius: 0.25rem;
// }

#pdf-download {
  display: none;
}

.react-doc {
  .ant-modal-body {
    padding: 0;
  }
  .kJTuaB {
    color: '#ffffff';
  }
}

.qborder {
  border: 1pt solid #e8e8e9;
  height: 700px;
  overflow: auto;
}
.qheader {
  border: 1pt solid #e8e8e9;
  overflow: none;
}
.qbavathar {
  background-color: #3c2a5e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: medium;
}
.qbavatharactive {
  background-color: #ffffff;
  border: 1pt solid #3c2a5e;
  color: #3c2a5e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: medium;
}

.qbquestion {
  background-color: #3c2a5e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 10px;
  font-size: 10px;
}

$primary: #3c2a5e;
$secondary: #7f60b8;
$shadow-secondary: rgba(127, 96, 184, 0.2);
$white: #ffffff;
$grey: rgba(0, 0, 0, 0.65);
$green: green;

.cp {
  cursor: pointer;
}

.cd {
  cursor: default;
}

.upp {
  text-transform: uppercase;
}

.cap {
  text-transform: capitalize;
}

.anticon {
  vertical-align: 0.125em;
}

.green-btn {
  background-color: $green !important;
  border-color: $green;
  color: $white;
  padding: 5px 15px;
  border-radius: 15px;
}

.input-container {
  width: 100%;
  height: 40px;
  margin-bottom: 2rem;
  position: relative;

  input {
    width: 100%;
    height: 40px;
    border-radius: 6px;
  }

  input:hover,
  input:focus {
    border-width: 1.2px;
    border-color: #3c2a5e;
    transition: 0.5s;
    box-shadow: none;
  }

  input[disabled] {
    background-color: transparent;
    cursor: not-allowed;
  }

  input[disabled]:hover {
    border-color: #d9d9d9;
  }

  .input-select {
    width: 100%;
    .ant-select-selector {
      width: 100%;
      height: 40px;
      border-radius: 6px;

      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        line-height: 38px;
      }

      .ant-select-selection-search-input {
        height: 100%;
      }
    }

    .ant-select-selector:hover {
      border-width: 1.2px;
      transition: 0.2s;
      border-color: #3c2a5e;
      box-shadow: none;
    }
  }

  .input-select-multiple {
    width: 100%;
    .ant-select-selector {
      width: 100%;

      border-radius: 6px;
    }

    .ant-select-selector:hover {
      border-width: 1.2px;
      transition: 0.2s;
      border-color: #3c2a5e;
      box-shadow: none;
    }
  }

  .ant-select-focused .ant-select-selector {
    border-width: 1.2px;
    transition: 0.2s;
    border-color: #3c2a5e;
    box-shadow: none;
  }

  .input-icon {
    position: absolute;
    right: 12px;
    top: 12px;
  }

  .date-input {
    width: 100%;
    height: 40px;
    border-radius: 6px;
  }

  .ant-picker-focused {
    border-color: $primary;
    box-shadow: none;
  }

  .date-input:hover,
  .date-input:focus {
    border-width: 1.2px;
    border-color: $primary;
    transition: 0.5s;
    box-shadow: none;
  }
}

.textarea-container {
  width: 100%;
  margin-bottom: 2rem;

  textarea {
    width: 100%;
    border-radius: 6px;
  }

  textarea:hover,
  textarea:focus {
    border-width: 1px;
    border-color: #3c2a5e;
    transition: 0.5s;
    box-shadow: none;
  }
}

.form-err {
  color: tomato;
  float: left;
  padding-top: 3px;
  padding-left: 8px;
}

.small-text {
  float: right;
  padding-top: 3px;
  padding-right: 8px;
}

.field-array {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &-label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 0.875rem;
    font-weight: 400;
    padding: 10px 5px;
    line-height: 1.5715;
    white-space: nowrap;
  }

  &-add {
    color: $secondary;
    &:hover,
    &:focus {
      color: $primary;
    }
  }

  &-actions {
    position: absolute;
    right: -25px;
    display: flex;
    flex-direction: column;

    span:nth-child(1) {
      font-size: 20px;
    }

    span:nth-child(2) {
      font-size: 20px;
      margin-top: 8px;
    }
  }
}

.search-input-container {
  position: relative;
  input {
    color: $grey;
    font-size: 0.875rem;
    min-width: 180px;
    border: 1px solid #ededed;
    background: #fcfcfc;
    border-radius: 18px;
    padding: 5px 40px 5px 10px;
    &:focus {
      outline: none;
    }
  }

  :disabled {
    cursor: not-allowed;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.3);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, 0.3);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(0, 0, 0, 0.3);
  }
  .search-icon {
    position: absolute;
    right: 16px;
    top: 10px;
  }
}

.radio-btn {
  .ant-radio-checked .ant-radio-inner {
    border-color: $primary;
  }

  .ant-radio-inner::after {
    background-color: $primary;
  }

  .ant-radio-disabled .ant-radio-inner::after {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: $primary;
  }

  .ant-radio:focus .ant-radio-inner {
    border-color: $primary;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(178, 165, 255, 0.06);
  }
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner {
    border-color: $primary;
  }
}

.check-box {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary;
    border-color: $primary;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $primary;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary;
}

.header-breadcrumb {
  font-size: 1.125rem;

  .anticon {
    font-size: 1.25rem;
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right: 4px;
    .dark-mode & {
      color: $white;
    }
  }
  .ant-breadcrumb-link {
    .dark-mode & {
      color: $white;
    }
  }
  .ant-breadcrumb-separator {
    margin-left: 8px;
    margin-right: 10px;
    font-size: 1.375rem;
    color: rgba(0, 0, 0, 0.6);
    .dark-mode & {
      color: $white;
    }
  }

  a:hover {
    text-decoration: none;
    color: inherit;
  }

  span.ant-breadcrumb-link {
    color: $primary;
    .dark-mode & {
      color: $white;
      font-weight: bold;
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary;
  font-weight: 500;
  .dark-mode & {
    color: $white;
  }
}

.ant-tabs-tab:hover,
.ant-tabs-tab:focus {
  color: $primary;

  .dark-mode & {
    color: #ccc;
  }
}

.ant-tabs-ink-bar {
  background: $primary;
  .dark-mode & {
    background: $white;
  }
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
  .dark-mode & {
    color: #ccc;
  }
}

.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.navbar {
  background: #fff;
  padding: 0 1.25rem;
}

.buttn,
.buttn:hover,
.buttn:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
}
.rtButton {
  border: 0;
  outline: 0;
  box-shadow: none;
  width: 28px;
  height: 28px;
  background-color: #3c2a5e;
  color: #ffffff;
}
.icoButton,
.icoButton:hover,
.icoButton:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  background-color: #3c2a5e;
  color: #ffffff;
}
.icoButton:disabled:hover,
.icoButton:disabled {
  border: 0;
  outline: 0;
  box-shadow: none;
  border-radius: 10px;
  width: 35px;
  height: 35px;
  background-color: #3c2a5e;
  color: #ffffff;
  opacity: 0.5;
}

.icoButton2,
.icoButton2:hover,
.icoButton2:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
  height: 30px;
  background-color: #3c2a5e;
  color: #ffffff;
}
.icoButton2:disabled:hover,
.icoButton2:disabled {
  border: 0;
  outline: 0;
  box-shadow: none;
  height: 30px;
  background-color: #3c2a5e;
  color: #ffffff;
  opacity: 0.5;
}

.assign-btn {
  background-color: $primary;
  color: $white;
  padding: 5px 15px;
  border-radius: 15px;
}

.filter-icon {
  font-size: 1.375rem;
  margin-left: 1rem;
  color: #3c2a5e;
}

.filter-btn {
  // position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $white;
  color: #3c2a5e;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: 0.5s;
  border: none;
}

// .filter-btn:hover {
//   background-color: #eeeeee;
//   color: $secondary;
// }

.filter-btn:hover,
.filter-btn:focus {
  outline: 0;
}

.filter-dot {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 6px;
  height: 6px;
  background: tomato;
  border-radius: 100%;
}

.cont {
  display: flex;
  flex-wrap: wrap;
}

.abc {
  flex: 1 1 50%; /* Two columns layout */
}

.def {
  /* Your styling for each column item */
  margin: 10px;
}

.upload-card {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 10px;

  &-info {
    display: flex;
    flex-direction: column;
    margin: 0 10px;
  }

  &-filename {
    margin-bottom: 5px;
    font-size: 14px;
    color: $grey;
    word-break: break-all;
  }

  &-select {
    .ant-select-selector {
      padding: 0 !important;
    }
  }

  &-icon {
    margin-left: auto;
  }
}

.attachment-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;

  &-fileName {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #272727;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-icon {
    margin-left: auto;
  }
}

.prev-container {
  position: relative;
  width: 100%;
  height: 100%;

  &-close {
    position: absolute;
    top: -5px;
    right: -25px;
    font-size: 25px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }

  &-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}

.upload-btn:hover {
  border-color: #d9d9d9;
  color: $primary;
}

.upload-btn:focus {
  border-color: #d9d9d9;
  color: $primary;
}

.upload-btn:disabled {
  background-color: $white;
  color: $secondary;
}

.confirm_ok,
.confirm_ok:hover,
.confirm_ok:focus {
  background: #3c2a5e;
  border-color: #3c2a5e;
  color: $white;
}

.confirm_cancel,
.confirm_cancel:hover,
.confirm_cancel:focus {
  background: $white;
  border-color: #d9d9d9;
  color: inherit;
}

.pg-viewer-wrapper {
  height: 100% !important;
  overflow: hidden !important;

  .pdf-viewer-container {
    height: 100% !important;
    overflow-y: auto;
  }

  .spreadsheet-viewer {
    height: 100%;

    .sheet-names {
      input[type='button'] {
        padding: 5px 15px;
        color: #80868b;
        border-radius: 0;
        border: 1px solid #e8eaed;
        box-shadow: none;
        outline: none;
      }

      input[type='button']:hover,
      input[type='button']:focus,
      input[type='button']:active {
        background-color: #fff;
        color: #188038;
      }
    }

    .react-grid-Header {
      .react-grid-HeaderCell {
        &-sortable {
          pointer-events: none;
        }
      }
    }
  }

  .react-grid-Canvas {
    overflow: auto !important;
    height: 87.5vh !important;
  }
}

.ant-picker {
  &:hover,
  &-focused {
    border-color: $primary;
  }
  &-focused {
    box-shadow: 0 0 0 2px $shadow-secondary;
  }
}

.ant-select {
  box-shadow: none;
  &-focused {
    border-color: $primary;
    box-shadow: 0 0 0 2px $shadow-secondary;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  box-shadow: none;
  border-color: $primary;
  box-shadow: 0 0 0 2px $shadow-secondary;
}

@media only screen and (min-width: 960px) {
  .navbar {
    display: none;
  }
}

.ant-steps-item-process .ant-steps-item-icon {
  background: #3c2a5e !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #3c2a5e !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #3c2a5e !important;
  color: #3c2a5e !important;
}
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #3c2a5e !important;
  height: 2px !important;
}

.ant-steps-icon svg {
  color: #3c2a5e !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3c2a5e !important;
  border-color: #3c2a5e;
}

.ant-select {
  width: 100%;
  border-radius: 8px; /* Border radius */
  // border-color: #661476;
}

.ant-select-item-option-content {
  color: inherit; /* Option text color */
}

/* Customize the option background on hover */
/* Style options on hover except for the selected option */
.ant-select-item-option:not(.ant-select-item-option-selected):hover {
  background-color: #3c2a5e; /* Option background color on hover */
  opacity: 0.5;
  color: #fff; /* Option text color on hover */
}

/* Customize the selected option background */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #3c2a5e !important; /* Selected option background color */
  color: #fff; /* Selected option text color */
}

/*ant border input
.ant-input {
  border: 1px solid rgba(102, 20, 118, 0.3) !important;
}

.ant-select-selector {
  border: 1px solid rgba(102, 20, 118, 0.3) !important;
}

.ant-picker {
  border: 1px solid rgba(102, 20, 118, 0.3) !important;
}

.ant-select-selector:hover,
.ant-select-selector:focus-within {
  border: 1px solid #661476 !important;
}
 */
/* Define the spinning animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the .spinner element */
.spinners {
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  vertical-align: text-bottom;
  border: 0.2rem solid currentColor;
  border-right: 0.2rem solid transparent;
  background-color: currentColor;
  border-radius: 50%;
  animation: spinner-grow 0.75s linear infinite;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: none;
    opacity: 0.5;
  }
}

.dmOAwp {
  display: none;
}
.ant-btn-primary {
  background-color: #3c2a5e !important;
  border-color: #3c2a5e !important;
}

/* Style the main part of the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
  height: 8px;
}

/* Style the track (the area on which the thumb slides) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
}

/* Style the thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #3c2a5e; /* color of the thumb */
  // border-radius: 6px; /* rounded corners for the thumb */
}

/* Style the hover effect of the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #3c2a5e; /* color when hovering over the thumb */
}

.otp-box {
  width: 75px;
  height: 75px;
  font-weight: 800;
  font-size: xx-large;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #3c2a5e;
  color: #3c2a5e;
}
.otp-bot {
  border: none;
  outline: none;
  color: #ffffff;
  cursor: default;
  user-select: none;
  background-color: red;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.ck.ck-editor__main > .ck-editor__editable {
  min-height: 250px;
}

.marquee {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marquee > span {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.textAnalysis{
  // background-color: red;
  padding: 0;
  margin: 0;


  .textAnalysisCard {
    display: inline-block; /* Allows easy placement in layouts */
    padding: 5px; /* Space inside the card */
    cursor: pointer; /* Pointer cursor for clickable effect */
  }
  

}


.aiButton {
  color: #7f60b8 !important;
  border: 1pt solid !important; 
}


.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: 0 !important;
}
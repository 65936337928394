.br {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px;
}

.dashboard-wrapper {
  background-color: rgb(255, 255, 255);

  .dark-mode & {
    background-color: #272525;
  }
}

.dashboard-container {
  background-color: rgb(255, 255, 255);

  .dark-mode & {
    background-color: #272525;
    padding: 10px;
  }
}

.view-container {
  background-color: #ffffff;

  .dark-mode & {
    background-color: #272525;
    padding: 10px;
  }
}

.details-container {
  width: 100%;
  padding: 0.5rem 1rem;
  position: relative;
  background-color: #ffffff;

  .dark-mode & {
    background-color: #272525;
  }

  .detail-card {
    margin-bottom: 1.5rem;

    &-heading {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: #222222;
      padding-bottom: 15px;
      letter-spacing: 0.5px;
    }

    &-label {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      color: #595959;
      padding-bottom: 5px;
      letter-spacing: 0.5px;
      text-transform: capitalize;

      .dark-mode & {
        color: #bababa;
      }
    }

    &-value {
      font-size: 1rem;
      color: #1c1c1c;
      letter-spacing: 0.5px;

      .dark-mode & {
        color: #ffffff;
      }
    }

    &-link {
      font-size: 1rem;
      color: #7f60b8;
      text-decoration-line: underline;
      letter-spacing: 0.5px;
    }

    &-textarea {
      line-height: 1.5rem;
      white-space: pre-wrap;
    }
  }

  .status {
    background: #f2f2f2;
    color: #bdc3c7;
    padding: 10px 25px;
    text-align: center;
    border-radius: 8px;
    letter-spacing: 0.5px;
  }

  .details-actions {
    position: absolute;
    right: -10px;
    display: flex;
    flex-direction: column;

    span:nth-child(1) {
      font-size: 20px;
    }

    span:nth-child(2) {
      font-size: 20px;
      margin-top: 8px;
    }
  }
}

.details-container1 {
  width: 100%;
  // padding: 0.5rem 1rem;
  position: relative;

  .detail-card {
    margin-bottom: 1.5rem;
    word-break: break-all;

    &-heading {
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: #1c1c1c;
      padding-bottom: 15px;
      letter-spacing: 0.5px;
    }

    &-label {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      color: #595959;
      padding-bottom: 5px;
      letter-spacing: 0.5px;
      text-transform: capitalize;
    }

    &-value {
      font-size: 1rem;
      color: #1c1c1c;
      letter-spacing: 0.5px;
    }

    &-link {
      font-size: 1rem;
      color: #7f60b8;
      text-decoration-line: underline;
      letter-spacing: 0.5px;
    }

    &-textarea {
      line-height: 1.5rem;
      white-space: pre-wrap;
    }
  }

  .status {
    background: #f2f2f2;
    color: #bdc3c7;
    padding: 10px 25px;
    text-align: center;
    border-radius: 8px;
    letter-spacing: 0.5px;
  }

  .details-actions {
    position: absolute;
    right: -10px;
    display: flex;
    flex-direction: column;

    span:nth-child(1) {
      font-size: 20px;
    }

    span:nth-child(2) {
      font-size: 20px;
      margin-top: 8px;
    }
  }
}

.detail-status {
  display: flex;
  align-items: center;

  span:first-child {
    font-size: 16px;
    margin-right: 5px;
  }

  span:nth-child(2) {
    font-size: 14px;
  }
}

.ant-table {
  :hover {
    .ant-table > tr > th {
      background-color: #3c2a5e4d;

      .dark-mode & {
        background-color: #505050;
      }
    }

    .ant-table-tbody > tr > td {
      background-color: #47474701;

      .dark-mode & {
        background-color: #2f2f2f;
        color: rgb(252, 252, 252);
      }
    }
  }
}

.view-container {
  width: 100%;
  margin: 0;
  padding: 1.25rem 1.5rem;
  overflow: auto;
  height: 85vh;

  &-footer {
    width: 100%;
    margin: 0;
    padding: 1.25rem 1.5rem;
    overflow: auto;
    height: 78vh;
  }

  .dark-mode & {
    table,
    tr,
    td,
    th {
      background-color: #060606;
      color: #ffffff;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &-label {
    background-color: #e7e7e7;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 4px;
    text-transform: uppercase;

    .dark-mode & {
      background-color: #272525;
      color: #ffffff;
      box-shadow: 0 5px 5px 0 rgb(52, 52, 52);
    }
  }
}

.view-container-rca {
  width: 100%;
  margin: 0;
  padding: 1rem 1rem;
  overflow: auto;
  overflow-x: hidden;
  max-height: 80vh;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &-label {
    background-color: #e8e8e9;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 4px;
    text-transform: uppercase;
  }
}

// .view-container::-webkit-scrollbar {
//   width: 0.25rem;
// }

// .view-container::-webkit-scrollbar-track {
//   background: #ffffff;
// }

// .view-container::-webkit-scrollbar-thumb {
//   background: #bdc3c7;
//   border-radius: 0.25rem;
// }

.sign-container {
  width: 100%;
  border: 1px solid #e8e8e9;
  border-radius: 4px;
  padding: 1rem 0.5rem;
  margin: 0;
  margin-bottom: 15px;

  .sign-card {
    &-label {
      font-style: normal;
      font-weight: 500;
      font-size: 0.875rem;
      color: #595959;
      padding-bottom: 5px;
      letter-spacing: 0.5px;
    }

    &-value {
      font-size: 1rem;
      color: #1c1c1c;
      letter-spacing: 0.5px;
    }

    &-textarea {
      line-height: 1.5rem;
      white-space: 'pre-wrap'; // Set to 'nowrap' to prevent word breaks
    }
  }
}
.antd-vendor-master {
  border: 1px solid rgb(213, 211, 211);
  border-top: none;

  .ant-tabs-nav {
    border: none;
    /* Reset other borders */
    border-bottom: 1px solid rgb(242, 236, 236);

    .ant-tabs-tab {
      margin: 0;
      // padding: 8px 16px;
      // background: #e7e4e48d;
      // border: 1px solid rgb(213, 211, 211);
      border-bottom: none;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 50%;
      justify-content: center;

      &-active {
        color: rgb(53, 37, 122);
        background: rgb(255, 255, 255);
        border-bottom: none;
        border: 1px solid rgb(213, 211, 211);
      }
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.antd-action-master {
  // .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
  // .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  //   position: fixed !important; /* or whatever you prefer */
  //   visibility: hidden !important;
  //   pointer-events: auto !important;
  // }

  // .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
  // .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  //   display: auto !important;
  //   align-self: auto !important;
  // }
  .ant-tabs-nav {
    border: none;
    /* Reset other borders */
    // border-bottom: 1px solid rgb(242, 236, 236);
    .ant-tabs-tab {
      margin: 0;
      padding: 8px 16px;
      transition: none;
      width: 50%;
      justify-content: center;

      &-active {
        background: #ece6f7;
        border-bottom: none;
        // border: 1px solid rgb(213, 211, 211);
        .ant-tabs-tab-btn {
          color: #3c2a5e !important;
        }
      }
    }

    .ant-tabs-nav-operations {
      position: absolute;
      visibility: hidden;
      pointer-events: none;
    }
    .ant-tabs-nav-operations-hidden {
      display: auto !important;
      align-self: normal !important;
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.antd-master {
  border: 1px solid rgb(213, 211, 211);
  border-top: none;

  .ant-tabs-nav {
    border: none;
    /* Reset other borders */
    border-bottom: 1px solid rgb(242, 236, 236);

    .ant-tabs-tab {
      margin: 0;
      padding: 8px 16px;
      // background: #e7e4e48d;
      border: 1px solid rgb(213, 211, 211);
      border-bottom: none;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 50%;
      justify-content: center;

      &-active {
        color: rgb(53, 37, 122);
        background: rgb(255, 255, 255);
        border-bottom: none;
        border: 1px solid rgb(213, 211, 211);
      }
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.antd-client {
  .ant-tabs-nav {
    border: none;
    .ant-tabs-nav-list {
      overflow-y: auto;
      position: relative;
      height: 100%;
    }
    .ant-tabs-nav-more {
      display: none;
    }
    .ant-tabs-tab {
      margin: 0;
      padding: 8px 16px;
      border: none;
      background: rgb(255, 255, 255);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      width: 100%;
      justify-content: start;

      &-active {
        background: #f1edf7;
        border: none;
      }
    }
  }

  .ant-tabs-nav-list {
    display: flex;
    justify-content: start;
    width: 100%;
    position: relative;
  }
}

.antd-btn-client {
  border-bottom: 1px solid rgb(242, 236, 236);
}

.chart-audit {
  border-radius: 30px;
  // min-width: 450px;
  // max-width: 200px;
  width: 200;
}

.chart-audit-head {
  font-size: 10px;
  font-weight: 700;
  color: #3c2a5e;
}

.chart-audit-body {
  font-size: 10px;
  color: #3c2a5e;
  font-weight: 500;
}

.audit-card-component {
  border-radius: 10px;
}

.audit-chart {
  .ant-table-thead > tr > th {
    background-color: #3c2a5e;
    color: #ccc;
    font-weight: 800;
  }

  .ant-table-tbody > tr > td {
    background-color: #f1edf7;
    border-bottom: 1pt solid #3c2a5e;
    border-left: 1pt solid #3c2a5e;
  }

  // .ant-table-row {
  //   border: 1pt solid #3c2a5e;
  // }

  .ant-table table {
    border: 1pt solid #3c2a5e;
    border-bottom: none;
    border-left: none;
  }
}
